import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Form,
  Spinner,
  Button,
  Modal,
} from "react-bootstrap"
import { navigate } from "gatsby"
import Metatags from "../../components/metatags"
import {
  getUser,
  saveUser,
  handleSendNewEmail,
  handleSendPasswordRecovery,
} from "../../services/auth"
import { publish } from "../../services/admin"
import AdmNavbar from "../../components/admNavbar"
import Footer from "../../components/footer"
import ErrorModal from "../../components/error-modal"
import "../../css/multilogica-theme.scss"

const regExEmail = new RegExp(
  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
)

function Account() {
  const [renderPage, setRenderPage] = useState(false)
  const [renderAskForEmail, setRenderAskForEmail] = useState(true)
  const [errorField, setErrorField] = useState([])
  const [errorFieldAdd, setErrorFieldAdd] = useState([])
  const [buttonSpinner, setButtonSpinner] = useState(false)
  const [sendEmailButton, setSendEmailButton] = useState(true)
  const [buttonEnabled, setButtonEnabled] = useState(true)
  const [modalDataSaved, setModalDataSaved] = useState(false)
  const [modalEmailChanged, setModalEmailChanged] = useState(false)
  const [emailChangeWarning, setEmailChangeWarning] = useState(false)
  const [modalInfo, setModalInfo] = useState("")
  const [userId, setUserId] = useState("")
  const [formEmail, setFormEmail] = useState("")
  const [emailOnTheRecord, setEmailOnTheRecord] = useState("")
  const [showErrorConnModal, setShowErrorConnModal] = useState(false)
  const [spinnerPasswordRecovery, setSpinnerPasswordRecovery] = useState(false)
  const [modalEmailSent, setModalEmailSent] = useState(false)
  const [modalConfirmPublish, setModalConfirmPublish] = useState(false)
  const [modalPUblishStarted, setModalPUblishStarted] = useState(false)

  useEffect(() => {
    async function startPage() {
      const jwt = localStorage.getItem("jwt")
      if (jwt) {
        await getUser(jwt)
          .then(res => {
            if (res.status === 200) {
              if (!res.isAxiosError) {
                setRenderPage(true)
                setRenderAskForEmail(!res.data.emailVerified)
                setUserId(res.data.id)
                setFormEmail(res.data.email)
                setEmailOnTheRecord(res.data.email)
              } else {
                setShowErrorConnModal(true)
              }
            } else {
              localStorage.clear()
              navigate("/sessao-expirada")
            }
          })
          .catch(error => {
            localStorage.clear()
            navigate("/sessao-expirada")
          })
      } else {
        navigate("/403")
      }
    }
    startPage()
  }, [])

  const sendPasswordRecovery = async () => {
    setSpinnerPasswordRecovery(true)
    await handleSendPasswordRecovery({ email: formEmail }).then(res => {
      if (!res.isAxiosError) {
        setModalEmailSent(true)
      }
    })
  }

  const verifyData = async () => {
    let errorFieldAux = []
    if (!regExEmail.test(formEmail)) {
      errorFieldAux.push("formEmail")
    }

    if (errorFieldAux.length !== 0) {
      setErrorField(errorFieldAux)
    } else {
      let jwt = localStorage.getItem("jwt")
      let data = {
        id: userId,
        email: formEmail,
        emailVerified: formEmail === emailOnTheRecord,
      }
      await saveUser(jwt, data).then(res => {
        if (!res.isAxiosError) {
          if (res.status === 200) {
            if (typeof res.data.mailsent !== "undefined" && res.data.mailsent) {
              setModalDataSaved(true)
              setModalInfo(
                "Seus dados foram registrados e enviamos um novo email de confirmação"
              )
              setEmailChangeWarning(false)
              localStorage.setItem("jwt", res.data.newToken)
            } else {
              setModalDataSaved(true)
              setModalInfo("Seus dados foram registrados")
            }
          } else {
            setModalDataSaved(true)
            setModalInfo("Houve algum problema salvando seus dados.")
          }
        } else {
          setShowErrorConnModal(true)
        }
      })
    }
  }

  const formateField = evt => {
    evt.preventDefault()
    setFormEmail(evt.target.value)
    switch (evt.target.id) {
      case "formEmail":
        if (regExEmail.test(evt.target.value)) {
          removeError(evt.target.id)
        }
        break
      default:
        if (evt.target.value !== "") {
          removeError(evt.target.id)
        }
    }
  }

  const removeError = errName => {
    if (errorField.includes(errName)) {
      let errorFieldAux = errorField
      errorFieldAux.splice(errorFieldAux.indexOf(errName), 1)
      setErrorField(errorFieldAux)
    }
    if (errorFieldAdd.includes(errName)) {
      let errorFieldAux = errorFieldAdd
      errorFieldAux.splice(errorFieldAux.indexOf(errName), 1)
      setErrorFieldAdd(errorFieldAux)
    }
  }

  const sendNewEmail = async () => {
    setButtonEnabled(false)
    setButtonSpinner(true)
    await handleSendNewEmail({
      email: formEmail,
    }).then(res => {
      if (!res.isAxiosError) {
        if (res.status === 201) {
          setSendEmailButton(false)
        }
      } else {
        setShowErrorConnModal(true)
      }
    })
  }

  const removeModal = () => {
    setModalDataSaved(false)
    setModalEmailSent(false)
  }

  const removeModalEmailChanged = () => {
    setModalEmailChanged(!modalEmailChanged)
  }

  const verifyEmailChange = evt => {
    evt.preventDefault()
    if (!emailChangeWarning) {
      setModalEmailChanged(true)
      setEmailChangeWarning(true)
    }
  }

  const removeErrorConnModal = () => {
    setShowErrorConnModal(!showErrorConnModal)
  }

  const setConfirmPublishModal = () => {
    setModalConfirmPublish(!modalConfirmPublish)
  }

  const setPublishStartedModal = () => {
    setModalPUblishStarted(!modalPUblishStarted)
  }

  const publishChanges = async () => {
    const jwt = localStorage.getItem("jwt")
    if (jwt !== "") {
      const data = {
        permission: "publish-changes",
      }
      await publish(jwt, data).then(res => {
        if (res.status === 201) {
          setModalConfirmPublish(false)
          setModalPUblishStarted(true)
        } else {
          console.log("erro", res)
        }
      })
    }
  }

  return (
    <>
      <ErrorModal
        modalErro={showErrorConnModal}
        removeErrorConnModal={removeErrorConnModal}
      />
      <Modal show={modalEmailSent}>
        <Modal.Body>
          <p>Enviamos um email com um link para você alterar sua senha.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => removeModal()}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <Metatags title="" description="" url="" image="" keywords="" />
      <Modal show={modalDataSaved}>
        <Modal.Body>
          <p>{modalInfo}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => removeModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalEmailChanged}>
        <Modal.Body>
          <p>
            Caso seu email seja alterado, Você receberá um novo pedido de
            confirmação.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => removeModalEmailChanged}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalConfirmPublish}>
        <Modal.Body>Confirma a pubicação das alterações?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setConfirmPublishModal}>
            Fechar
          </Button>
          <Button variant="danger" onClick={publishChanges}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalPUblishStarted}>
        <Modal.Body>
          Processo de publicação iniciado corretamente. Em alguns minutos as
          alterações estarão disponíveis.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={setPublishStartedModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <AdmNavbar />
      <div className="height-40" />
      {renderPage ? (
        <>
          {renderAskForEmail ? (
            <Container>
              <Row>
                <Col sm={2} />
                <Col sm={8}>
                  <Container>
                    <Row>
                      <Col>
                        <p>
                          Antes de acessar sua conta confirme a sua inscrição
                          através do email que enviamos para você.
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {sendEmailButton ? (
                          <>
                            <Button
                              variant="primary"
                              onClick={() => sendNewEmail}
                              style={{ float: "left" }}
                              disabled={!buttonEnabled}
                            >
                              Enviar novo email
                            </Button>
                            <div
                              style={{
                                display: buttonSpinner ? "block" : "none",
                                float: "left",
                                margin: "4px 0 0 6px",
                              }}
                            >
                              <Spinner animation="border" variant="secondary" />
                            </div>
                          </>
                        ) : (
                          <p>Um novo email foi enviado para você.</p>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container>
              <Row>
                <Col>
                  <Container className="user-data">
                    <div className="height-40" />
                    <Row>
                      <Col>
                        <Button
                          variant="danger"
                          onClick={() => setConfirmPublishModal()}
                        >
                          Publicar alterações
                        </Button>
                      </Col>
                    </Row>
                    <div className="height-40" />
                    <Row>
                      <Col>
                        <h4>Meus dados</h4>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <Form.Group controlId="formEmail">
                          <Form.Label>email</Form.Label>
                          <Form.Control
                            type="text"
                            value={formEmail}
                            onChange={evt => formateField(evt)}
                            onFocus={evt => verifyEmailChange(evt)}
                            className={
                              errorField.includes("formEmail")
                                ? "errorBorder"
                                : ""
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="height-40" />
                    <Row>
                      <Col>
                        <Button onClick={() => verifyData()}>
                          Salvar email
                        </Button>
                      </Col>
                    </Row>
                    <div className="height-40" />
                    <Row>
                      <Col>
                        <Button onClick={() => sendPasswordRecovery()}>
                          Alterar senha
                        </Button>
                      </Col>
                      <div
                        style={{
                          display: spinnerPasswordRecovery ? "block" : "none",
                          float: "left",
                          margin: "4px 6px 0 0",
                        }}
                      ></div>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          )}
          <div className="height-40" />

          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default Account
